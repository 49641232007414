import React from "react";
import styled from "styled-components";

function Techno({ name, logo }) {
  return (
    <TechnoStyled>
      <img src={logo} alt={name} />
      <p>{name}</p>
    </TechnoStyled>
  );
}

const TechnoStyled = styled.div`
  display: flex;
  justify-content: left;
  position: relative;
  padding: 0.5rem 0;
  min-width: 40px;

  &:hover {
    padding-left: 10px;
    transition: all 0.5s ease-in-out;
  }

  &::before {
    content: "";
    position: absolute;
    margin-left: -5px;
    bottom: -5px;
    width: 0rem;
    height: 0.2rem;
    background-color: var(--primary-color);
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
  }

  &:hover {
    &::before {
      content: "";
      margin-left: 1px;
      position: absolute;
      bottom: -5px;
      width: 5rem;
      height: 0.2rem;
      background-color: var(--background-light-color-2);
      border-radius: 10px;
    }
  }

  p {
    padding: 0 0.8rem;
  }
`;

export default Techno;
