import React from "react";
import styled from "styled-components";
import Navigation from "./Navigation";

function Sidebar({ navToggle, handleCallback }) {
  return (
    <SidebarStyled className={`${navToggle ? "nav-toggle" : ""}`}>
      <Navigation handleCallback={handleCallback} />
    </SidebarStyled>
  );
}

const SidebarStyled = styled.div`
  width: 15rem;
  position: fixed;
  height: 100vh;
  background-color: var(--sidebar-dark-color);
  transition: all 0.2s ease-in-out;
  z-index: 1;

  @media screen and (max-width: 1200px) {
    transform: translateX(-100%);
    z-index: 20;
    width: 12rem;
  }
`;

export default Sidebar;
