import React from "react";
import { MainLayout, InnerLayout } from "../styles/Layouts";
import Title from "../Components/Title";
import styled from "styled-components";

import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import LocationIcon from "@material-ui/icons/LocationOn";
import ContactItem from "../Components/ContactItem";
import ContactForm from "../Components/ContactForm";

//EMAILJS
// import { useRef } from "react";
// import emailjs from "emailjs-com";
import { init } from "emailjs-com";
init("user_IxWJ6zyG7O3N9sB4YSi87");

function ContactPage() {
  const phone = <PhoneIcon />;
  const email = <EmailIcon />;
  const location = <LocationIcon />;
  // material UI icons need to be set into variables

  return (
    <MainLayout>
      <Title title={"Contact"} />
      <ContactPageStyled>
        <InnerLayout className={"contact-section"}>
          <div className="left-content-page">
            <div className="contact-title">
              <h4>Get In Touch</h4>
            </div>
            <ContactForm />
          </div>

          <div className="right-content-page">
            <ContactItem icon={phone} title={"Telegram"} contact={"@EdenLs"} />

            <ContactItem
              icon={email}
              title={"Email"}
              contact={"eden.ls.dev@gmail.com"}
            />

            <ContactItem
              icon={location}
              title={"Location"}
              contact={"75012 Paris, France"}
            />
          </div>
        </InnerLayout>
      </ContactPageStyled>
    </MainLayout>
  );
}

const ContactPageStyled = styled.section`
  .contact-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.8rem;

    @media screen and (max-width: 1000px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .right-content-page {
    display: flex;
    height: 450px;
    flex-direction: column;
    margin: 6rem 1rem;
    padding: 2.5rem 0;
    border-radius: 15px;
    background-color: var(--background-dark-grey);

    @media screen and (max-width: 1000px) {
      width: 85%;
      height: 85%;
      margin: 1rem auto;
      font-size: 0.8rem;
      padding: 2rem 2rem;
    }
  }

  .left-content-page {
    @media screen and (max-width: 1000px) {
      text-align: center;
      margin-bottom: 2rem;
    }

    .contact-title {
      h4 {
        color: var(--white-color);
        font-size: 1.6rem;
        padding: 1rem 0;
      }
    }
  }
`;

export default ContactPage;
