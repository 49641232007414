import React from "react";
import styled from "styled-components";
import Github from "@material-ui/icons/GitHub";
import OpenBrowser from "@material-ui/icons/OpenInBrowser";

function Menu({ menuItem }) {
  return (
    <MenuItemStyled>
      {menuItem.map((item) => {
        let cat1 = item.category;
        let cat1toClass = cat1.toLowerCase().replace(/\W/g, "");
        let cat2 = item.category2 ? item.category2 : "";
        let cat2toClass = cat2.toLowerCase().replace(/\W/g, "");
        let cat3 = item.category3 ? item.category3 : "";
        let cat3toClass = cat3.toLowerCase().replace(/\W/g, "");
        let cat4 = item.category4 ? item.category4 : "";
        let cat4toClass = cat4.toLowerCase().replace(/\W/g, "");

        return (
          <div className="grid-item" key={item.id}>
            <div className="portfolio-content">
              <div className="portfolio-image">
                <img src={item.image} alt="" />
                <ul>
                  {item.link1 && (
                    <li>
                      <a href={item.link1} target="_blank" rel="noreferrer">
                        <Github />
                      </a>
                    </li>
                  )}
                  {item.link2 && (
                    <li>
                      <a href={item.link2} target="_blank" rel="noreferrer">
                        <OpenBrowser />
                      </a>
                    </li>
                  )}
                </ul>
              </div>
              <h6>{item.title}</h6>
              <p>{item.text}</p>
              <div className="tags">
                <div className={cat1toClass}>
                  <p>{item.category}</p>
                </div>
                <div className={cat2toClass}>
                  <p>{item.category2 ? item.category2 : ""}</p>
                </div>
                <div className={cat3toClass}>
                  <p>{item.category3 ? item.category3 : ""}</p>
                </div>
                <div className={cat4toClass}>
                  <p>{item.category4 ? item.category4 : ""}</p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </MenuItemStyled>
  );
}

const MenuItemStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;

  p {
    font-size: 1rem;
  }

  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 3rem;
  }

  .grid-item {
    .portfolio-content {
      display: block;
      position: relative;

      img {
        width: 100%;
        height: 30vh;
        object-fit: cover;
        border-radius: 15px;
        box-shadow: 0px 0px 5px 5px var(--font-light-color-2);

        @media screen and (max-width: 800px) {
          height: 38vh;
        }
      }

      ul {
        display: none;
        transform: translateY(-100px);
        left: 50%;
        top: 35%;
        transition: all 0.4s ease-in-out;
      }

      .portfolio-image {
        &::before {
          content: "";
          position: absolute;
          transition: all 0.4s ease-in-out;
        }
      }

      .portfolio-image:hover {
        ul {
          display: block;
          position: absolute;
          transform: translate(-50%, -90%);
          display: flex;
          align-items: center;
          justify-content: center;

          li {
            border: 2px solid var(--border-color);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin: 0 0.5rem;
            cursor: pointer;
            padding: 0.4rem;
            transition: all 0.4s ease-in-out;

            a {
              display: flex;
              align-items: center;
              justify-content: center;
            }

            &:hover {
              border: 2px solid var(--primary-color);
              color: var(--primary-color);

              svg {
                color: var(--primary-color);
                transition: all 0.4s ease-in-out;
              }
            }
          }

          svg {
            font-size: 2rem;
            color: var(--border-color);
          }
        }

        &::before {
          width: 100%;
          height: 30vh;
          background-color: var(--white-color);
          opacity: 0.8;
          border-radius: 15px;
          transition: all 0.2s ease-in-out;

          @media screen and (max-width: 800px) {
            height: 38vh;
          }
        }
      }
    }

    .tags {
      display: flex;
      flex-wrap: wrap;
      align-items: left;
      color: white;
      padding: 0.6rem 0;

      @media screen and (max-width: 500px) {
        padding: 0.4rem;
      }

      p {
        padding: 0.4rem;
        font-size: 0.7rem;
      }

      .css {
        background-color: #8d0bad;
        border-radius: 10px;
        margin-right: 0.5rem;
        @media screen and (max-width: 500px) {
          margin-top: 0.5rem;
        }
      }

      .nodejs {
        background-color: #a06cd5;
        border-radius: 10px;
        margin-right: 0.5rem;
        @media screen and (max-width: 500px) {
          margin-top: 0.5rem;
        }
      }

      .javascript {
        background-color: #d2b7e5;
        border-radius: 10px;
        margin-right: 0.5rem;
        @media screen and (max-width: 500px) {
          margin-top: 0.5rem;
        }
      }

      .reactjs {
        background-color: #6b33ac;
        border-radius: 10px;
        margin-right: 0.5rem;
        @media screen and (max-width: 500px) {
          margin-top: 0.5rem;
        }
      }

      .reactnative {
        background-color: #3f37c9;
        border-radius: 10px;
        margin-right: 0.5rem;
        @media screen and (max-width: 500px) {
          margin-top: 0.5rem;
        }
      }

      .typescript {
        background-color: #c270cf;
        border-radius: 10px;
        margin-right: 0.5rem;
        @media screen and (max-width: 500px) {
          margin-top: 0.5rem;
        }
      }

      .sql {
        background-color: #3a0ca3;
        border-radius: 10px;
        margin-right: 0.5rem;
        @media screen and (max-width: 500px) {
          margin-top: 0.5rem;
        }
      }

      .mongodb {
        background-color: #4361ee;
        border-radius: 10px;
        margin-right: 0.5rem;
        @media screen and (max-width: 500px) {
          margin-top: 0.5rem;
        }
      }
    }
  }
`;

export default Menu;
