import React from "react";
import styled from "styled-components";
import resume from "../img/javascript-wizard-developer.jpg";
import PrimaryButton from "./PrimaryButton";

function ImageSection() {
  return (
    <ImageSectionStyled>
      <div className='left-content'>
        <img src={resume} alt='resume' />
      </div>
      <div className='right-content'>
        <div className='sub-title'>
          <h4>
            I am <span>Eden 🌈</span>
          </h4>
        </div>
        <p className='paragraph'>
          I've learned programming with online courses alone for years, and decided to enter a programming school in 2021 to boost my skills and become a professional developer.
        </p>
        <div className='about-info'>
          <div className='info-title'>
            <p>Full Name:</p>
            <p>Nationality:</p>
            <p>Languages:</p>
            <p>Location:</p>
            <p>Experience:</p>
            <p>Service:</p>
          </div>

          <div className='info'>
            <p>Eden C. Louis</p>
            <p>French</p>
            <p>French, English</p>
            <p>Remote, France</p>
            <p>+3 years</p>
            <p>FullStack Developer</p>
          </div>
        </div>

        {/* <PrimaryButton
          title={"Download CV 🇫🇷"}
          link={
            "https://drive.google.com/file/d/1WFIJedEX0Xe42QQeg9bZPzRax42mAR1g/view?usp=sharing"
          }
          target="_blank"
        /> */}
      </div>
    </ImageSectionStyled>
  );
}

const ImageSectionStyled = styled.div`
  margin-top: 4rem;
  display: flex;

  .left-content{
  img {
      margin-top: 2rem;
      width: 80%;
      object-fit: cover;
      border-radius: 2rem;
    }
  }

  @media screen and (min-width: 1500px) {
    .left-content{
  img {
      width: 60%;
      object-fit: cover;
      border-radius: 2rem;
      margin-left: 4rem;
    }
  }
  }

    @media screen and (max-width: 1000px) {
      flex-direction: column;
      align-items: center;

      .left-content {
        margin-bottom: 1.5rem;
        text-align: center;

        img {
          margin-top: 0;
        width: 55%;
        object-fit: cover;
        border-radius: 2rem;
      }
      }
    }
    .right-content {
      text-align: center;

      .about-info {
        justify-content: center;
      }
    }

    .left-content {
      width: 90%;
    }
  }

  @media screen and (max-width: 500px) {
    .info-title {
      p {
        font-size: 0.9rem;
        margin-top: 0.4rem;
        padding-right: 0.5rem;
      }
    }

    .info {
      p {
        font-size: 0.9rem;
        margin-top: 0.4rem;
      }

      .left-content {
        width: 90%;

        img {
          margin-top: 0;
          width: 80%;
          object-fit: cover;
          border-radius: 2rem;
        }
      }
    }

    span {
      font-size: 1rem;
    }
  }

  .right-content {
    width: 100%;

    h4 {
      font-size: 2rem;
      color: var(--white-color);

      span {
        font-size: 2rem;
      }
    }

    .paragraph {
      padding: 1rem 0;
    }

    .about-info {
      display: flex;
      padding-bottom: 2rem;

      .info-title {
        padding-right: 2rem;

        p {
          font-weight: 600;
        }
      }

      .info-title,
      .info {
        p {
          padding: 0.2rem 0;
        }
      }
    }
  }
`;

export default ImageSection;
