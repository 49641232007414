import React from "react";
import styled from "styled-components";

function PrimaryButton({ title, link, target }) {
  return (
    <PrimaryButtonStyled href={link} target={target}>
      {title}
    </PrimaryButtonStyled>
  );
}

const PrimaryButtonStyled = styled.a`
  background-color: var(--primary-color);
  padding: 0.7rem 1.7rem;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: inherit;
  border-radius: 15px;
  transition: all 1s ease-in-out;

  &:hover {
    position: relative;
    top: 4px;
  }
`;

export default PrimaryButton;
