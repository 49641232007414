import React from "react";
import styled from "styled-components";
import Particle from "../Components/Particle";

import GithubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

function HomePage() {
  return (
    <HomePageStyled>
      <div className='particle-container'>
        <Particle />
      </div>

      <div className='typography'>
        <h1>
          Hi, I'm Eden
          <div className='typewriter'>
            <span> The Web Wizard 👋</span>
          </div>
        </h1>
        <p>A french web developer, building user-friendly websites & apps using modern languages & frameworks, with a strong interest in business issues, along with product design & management.</p>

        <div className='icons'>
          <a href='https://github.com/Ed-Ls' target='_blank' rel='noreferrer' className='icon i-github'>
            <GithubIcon />
          </a>
          <a href='https://www.linkedin.com/in/e-c-ls/' target='_blank' rel='noreferrer' className='icon i-linkedin'>
            <LinkedInIcon />
          </a>
        </div>
      </div>
    </HomePageStyled>
  );
}

const HomePageStyled = styled.header`
  width: 100%;
  height: 100vh;
  position: relative;

  h1 {
    font-size: 3rem;
  }

  p {
    margin: 1rem 0;
  }

  .typography {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 80%;

    span {
      font-size: 3rem;
      display: block;
    }

    .icons {
      display: flex;
      justify-content: center;
      margin-top: 1rem;

      .icon {
        border: 2px solid var(--border-color);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin: 0 0.5rem;
        cursor: pointer;
        transition: all 0.4s ease-in-out;

        &:hover {
          border: 2px solid var(--primary-color);
          color: var(--primary-color);
        }
        svg {
          margin: 0.5rem;
        }
      }
    }
  }

  @media screen and (min-width: 1075px) {
    .typewriter span {
      overflow: hidden; /* Ensures the content is not revealed until the animation */
      border-right: 0.1em solid var(--primary-color); /* The typwriter cursor */
      white-space: nowrap; /* Keeps the content on a single line */
      margin: 0 auto; /* Gives that scrolling effect as the typing happens */
      letter-spacing: 0.12em; /* Adjust as needed */
      animation: typing 3.5s steps(35, end), blink-caret 0.75s step-end infinite;
    }

    /* The typing effect */
    @keyframes typing {
      from {
        width: 0;
      }
      to {
        width: 100%;
      }
    }

    /* The typewriter cursor effect */
    @keyframes blink-caret {
      from,
      to {
        border-color: transparent;
      }
      50% {
        border-color: var(--primary-color);
      }
    }
  }

  @media screen and (min-width: 1200px) and (max-width: 1300px) {
    .typewriter span {
      font-size: 2.5rem;
    }
  }

  @media screen and (max-width: 1000px) {
    .typewriter span {
      font-size: 2.5rem;
    }

    h1 {
      font-size: 2.5rem;
    }
  }

  @media screen and (max-width: 700px) {
    .typewriter span {
      font-size: 1.5rem;
    }

    h1 {
      font-size: 1.5rem;
      padding-top: 1.5rem;
    }

    p {
      font-size: 1rem;
    }
  }
`;

export default HomePage;
