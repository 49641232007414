import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

    /* :root{
        --primary-color: #007bff;
        --primary-color-light: #057FFF;
        --secondary-color: #6c757d;
        --background-dark-color: #10121A;
        --background-dark-grey: #191D2B;
        --border-color: #2e344e;
        --background-light-color: #F1F1F1;
        --background-light-color-2: rgba(3,127,255,.3);
        --white-color: #FFF;
        --font-light-color: #a4acc4;
        --font-dark-color: #313131;
        --font-dark-color-2: #151515;
        --sidebar-dark-color: #191D2B;
        --scrollbar-bg-color: #383838;
        --scrollbar-thump-color: #6b6b6b;
        --scrollbar-track-color: #383838;
    } */

    .light-theme{
        --primary-color: #480CA8;
        --background-dark-color: #F1F1F1;
        --background-dark-grey: #e4e4e4;
        --border-color: #cbced8;
        --background-light-color-2: rgba(72,12,168,.3);
        --white-color: #10121A;
        --font-light-color: #313131;
        --font-light-color-2: rgba(48, 49, 49, .1);
        --font-dark-color: #313131;
        --font-dark-color-2: #10121A;
        --sidebar-dark-color: #E4E4E4;
        --scrollbar-bg-color: #383838;
        --scrollbar-thump-color: #6b6b6b;
        --scrollbar-track-color: #383838;
    }

    .dark-theme{
        --primary-color: #6F3AC3;
        --background-dark-color: #000814;
        --background-dark-grey: #15152F;
        --border-color: #322E4E;
        --background-light-color-2: rgba(86,11,173,.4);
        --white-color: #FFF;
        --font-light-color: #BEB5D1;
        --font-light-color-2: rgba(190, 181, 209, .1);
        --font-dark-color: #313131;
        --font-dark-color-2: #10121A;
        --sidebar-dark-color: #191D2B;
        --scrollbar-bg-color: #383838;
        --scrollbar-thump-color: #6b6b6b;
        --scrollbar-track-color: #383838;
    }

    *{
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        list-style: none;
        text-decoration: none;
        font-family: 'Nunito', sans-serif;
        font-size: 1.2rem;
    }

    body{
        background-color: var(--background-dark-color);
        color: var(--font-light-color)
    }

    body::-webkit-scrollbar{
        width: 9px;
        background-color: var(--scrollbar-track-color) ;
    }

    body::-webkit-scrollbar-thumb{
        border-radius: 15px;
        background-color: var(--scrollbar-thump-color) ;
    }

    body::-webkit-scrollbar-track{
        border-radius: 15px;
        background-color: var(--scrollbar-track-color) ;
    }

    a{
        font-family: inherit;
        color: inherit;
        font-size: inherit;
        font-size: 1rem;
    }

    h1{
        font-size: 4rem;
        color: var(--white-color);
        display: inline-block;
        
        span{
            font-size: 4rem;
        }
    }

    span{
        color: var(--primary-color);
    }

    h6 {
      color: var(--white-color);
      font-size: 1.3rem;
      padding-bottom: 0.4rem;
    }

    //floating darkmode toggler 

    .light-dark-mode {
    position: fixed;
    right: 0;
    top: 8rem;
    background-color: var(--background-light-color-2);
    width: 5rem;
    height: 2.5rem;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    z-index: 15;
    display: flex;
    align-items: center;
    justify-content: center;

    .left-content {
      display: flex;
      align-items: center;
      margin-left: 0.6rem;

      svg {
        font-size: 1.3rem;
        color: var(--white-color);
      }
    }
  }

  //Hamburger Menu
  .hamburger-menu{
      position: absolute;
      right: 5%;
      top: 3%;
      z-index: 16;
      display: none;

      svg{
          font-size: 3rem;
          color: var(---primary-color);
      }

    }

    .nav-toggle{
        transform: translateX(0);
        z-index: 20;
        box-shadow: 0px 0px 50px 5000px rgba(0,0,0,0.6);
    }

  @media screen and (max-width: 1200px) {
      .hamburger-menu
      {
          display: block;
      }
  }  

  @media screen and (max-width: 700px) {
    span {
      font-size: 1.5rem;
    }

    h1 {
      font-size: 1.5rem;
      padding-top: 3rem;
    }

    p {
      font-size: 1rem;
    }
  }

`;

export default GlobalStyle;
