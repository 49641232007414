import React from "react";
import styled from "styled-components";
import { InnerLayout } from "../styles/Layouts";
import SmallTitle from "./SmallTitle";
import Title from "./Title";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import SchoolIcon from "@material-ui/icons/School";
import ResumeItem from "./ResumeItem";

function Resume() {
  const briefcase = <BusinessCenterIcon />;
  const school = <SchoolIcon />;

  return (
    <ResumeStyled>
      <Title title={"Resume"} />
      <InnerLayout>
        <div className='small-title'>
          <SmallTitle icon={briefcase} title={"Working Experience"} />
        </div>
        <div className='resume-content'>
          <ResumeItem
            year={"2023 - Now"}
            title={"Fullstack Developer (React - .Net)"}
            subTitle={"Bump"}
            text={"I've joined Bump to work on its platform to help manage and decarbonize vehicle fleets. The stack: ReactJS, NextJS, Typescript, Storybook, C#, .Net, PostgreSQL..."}
          />

          <ResumeItem
            year={"2022 - 2023"}
            title={"Frontend Developer (React - React Native)"}
            subTitle={"Majelan"}
            text={"I've joined Majelan to work on its podcasts streaming platform. The stack: ReactJS, NextJS, React Native, Tailwind, SASS, Javascript, Typescript, API, Firebase, Vercel..."}
          />

          <ResumeItem
            year={"2020 - 2021"}
            title={"Digital & Communication Manager"}
            subTitle={"Immersiv.io"}
            text={"I joined the company to create and execute its digital marketing strategy: SEO, webmastering (Wordpress, HTML/CSS), product strategy, web design (Figma, Adobe Suite), CRM...."}
          />

          <ResumeItem
            year={"2019"}
            title={"Digital Marketing Assistant"}
            subTitle={"Antescofo (Metronaut App)"}
            text={"Working on growth & user acquisition through various channels: webmastering and website redesign (Wordpress, HTML/CSS), CRM, data analysis (Amplitude), SEO, SEA..."}
          />
        </div>

        <div className='small-title inner-title'>
          <SmallTitle icon={school} title={"Educational"} />
        </div>
        <div className='resume-content'>
          <ResumeItem
            year={"2021 - 2022"}
            title={"Fullstack Javascript Developer"}
            subTitle={"Ecole O'Clock"}
            text={"6 months training (798h) to become a web developer with JavaScript. Learning frontend with VanillaJS and React (+ Redux) & backend with Node.js, Express, SQL, PostgreSQL..."}
          />

          <ResumeItem
            year={"2020 - 2021"}
            title={"+200 hours, self-study of web development"}
            subTitle={"Udemy"}
            text={
              "I took courses on Udemy platform, to learn HTML/CSS (Sass, Tailwind, Bulma), Javascript (VanillaJS, Node.js, Express) and ReactJS (Redux, Next). I realized several projects during my courses."
            }
          />

          <ResumeItem
            year={"2018 - 2019"}
            title={"MBA Digital Marketing & Business"}
            subTitle={"EFAP"}
            text={"Degree in digital marketing and e-commerce: web project management and integrations (Wordpress, UX/UI Design, Prestashop), online acquisition tools (Google Ads, SEO, CRM)..."}
          />
        </div>
      </InnerLayout>
    </ResumeStyled>
  );
}

const ResumeStyled = styled.section`
  .small-title {
    margin-bottom: 3rem;
  }

  .inner-title {
    margin-top: 3rem;
  }

  .resume-content {
    border-left: 2px solid var(--border-color);
  }
`;

export default Resume;
