import React from "react";
import styled from "styled-components";
import { InnerLayout } from "../styles/Layouts";
import ServiceCard from "./ServiceCard";
import Title from "./Title";
import design from "../img/design.svg";
import backend from "../img/backend-dev.svg";
import frontend from "../img/frontend-dev.svg";

function ServicesSection() {
  return (
    <InnerLayout>
      <ServicesSectionStyled>
        <Title title={"Services"} span={"Services"} />
        <div className='services'>
          <ServiceCard
            image={frontend}
            title={"Front-end Developement"}
            paragraph={"I implement user interfaces, with CSS, Typescript, React & Next, with required optimizations (accessibility, SEO, responsive design...)"}
          />
          <ServiceCard
            image={backend}
            title={"Back-end Developement"}
            paragraph={"I'm able to build RESTful API's, set up databases, ensure security and deploy websites with Node.js or .Net and SQL"}
          />
          <ServiceCard image={design} title={"Webdesign, Project Management"} paragraph={"I have experience in UX/UI design, web project management, analytics or CRM integrations and many more..."} />
        </div>
      </ServicesSectionStyled>
    </InnerLayout>
  );
}

const ServicesSectionStyled = styled.section`
  .services {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 4rem;
    grid-gap: 1.5rem;

    @media screen and (max-width: 950px) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 2.5rem;
    }
  }
`;

export default ServicesSection;
