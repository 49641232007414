import React from "react";
import styled from "styled-components";
import Resume from "../Components/Resume";
import Skills from "../Components/Skills";
import { MainLayout } from "../styles/Layouts";

function ResumePage() {
  return (
    <ResumePageStyled>
      <MainLayout>
        <Skills />
        <Resume />
      </MainLayout>
    </ResumePageStyled>
  );
}

const ResumePageStyled = styled.div``;

export default ResumePage;
