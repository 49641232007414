import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";
import { Link } from "react-router-dom";
import { init } from "emailjs-com";
import styled from "styled-components";
init("user_IxWJ6zyG7O3N9sB4YSi87");

function ContactForm() {
  const [message, setMessage] = useState("");
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm("service_l5a7xfc", "template_zhyjn5g", form.current).then(
      (result) => {
        console.log(result.text);
        setMessage(`Thanks for your message, I'll contact you soon! 😁 `);
        document.querySelector("form").reset();
      },
      (error) => {
        console.log(error.text);
        setMessage(
          `Sorry, an error occured, try again or use my email directly! 😕 `
        );
        document.querySelector("form").reset();
      }
    );
  };

  //custom error message if input left blank
  const handleInputInvalid = (event) => {
    event.target.setCustomValidity(`This can't be left blank!`);
  };
  const handleInputChange = (event) => {
    event.target.setCustomValidity("");
  };

  return (
    <ContactFormStyled className="form" ref={form} onSubmit={sendEmail}>
      <div className="form-field">
        <label htmlFor="name">Enter your name</label>
        <input
          type="text"
          id="name"
          name="user_name"
          required
          onInvalid={handleInputInvalid}
          onInput={handleInputChange}
        />
      </div>

      <div className="form-field">
        <label htmlFor="email">Enter your email</label>
        <input
          type="email"
          id="email"
          name="user_email"
          required
          onInvalid={handleInputInvalid}
          onInput={handleInputChange}
        />
      </div>

      <div className="form-field">
        <label htmlFor="topic">Enter your topic</label>
        <input
          type="text"
          id="topic"
          name="topic"
          required
          onInvalid={handleInputInvalid}
          onInput={handleInputChange}
        />
      </div>

      <div className="form-field">
        <label htmlFor="">Tell me more</label>
        <textarea
          name="textarea"
          id="textarea"
          cols="30"
          rows="5"
          name="message"
          required
          onInvalid={handleInputInvalid}
          onInput={handleInputChange}
        ></textarea>
      </div>
      <div className="form-field">
        <div className="legal-information">
          Your information will only be used to respond to your request and to
          send you a business proposal if requested. We will never share your
          data. See our <Link to="/legals">Privacy Policy.</Link>
        </div>
        <input type="submit" value="Send Email" className="submit-btn" />
      </div>
      <div className="submit-message">{message}</div>
    </ContactFormStyled>
  );
}

const ContactFormStyled = styled.form`
      width: 100%;

      @media screen and (max-width: 1000px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .form-field {
        margin-top: 2rem;
        position: relative;
        width: 80%;

        label {
          position: absolute;
          left: 20px;
          top: -20px;
          display: inline-block;
          background-color: var(--background-dark-color);
          padding: 0 0.5rem;
          color: inherit;
        }

        input {
          border: 2px solid var(--border-color);
          outline: none;
          background: transparent;
          height: 50px;
          padding: 0.5rem 15px;
          width: 100%;
          color: inherit;
          border-radius: 15px;
        }

        .submit-btn{
        background-color: var(--primary-color);
        padding: 0.5rem 1.7rem;
        color: white;
        cursor: pointer;
        display: inline-block;
        font-size: inherit;
        border-radius: 15px;
        border: none;
        transition: all 1s ease-in-out;

        &:hover {
          position: relative;
          top: 4px;
        }
      }

        textarea {
          background-color: transparent;
          border: 2px solid var(--border-color);
          outline: none;
          color: inherit;
          width: 100%;
          padding: 0.8rem 0.1rem;
          border-radius: 15px;
        }
      }
      }

      .submit-message{
        margin-top: 2rem;
        position: relative;
        width: 80%;
        padding: 0rem .3rem;
      }

      .legal-information{
        font-size: 0.7rem;
        margin-bottom: 1rem;

        a{
          font-size: 0.7rem;
          text-decoration: underline;
        }
      }
`;

export default ContactForm;
