import styled from "styled-components";

export const MainLayout = styled.div`
  padding: 4rem;

  @media screen and (max-width: 1000px) {
    padding: 2rem;
    padding-top: 4rem;
  }
`;

export const InnerLayout = styled.div`
  padding: 4rem 0;

  @media screen and (max-width: 1000px) {
    padding: 3rem 0;
  }
`;
