import React from "react";
import { MainLayout, InnerLayout } from "../styles/Layouts";
import Title from "../Components/Title";
import styled from "styled-components";

function LegalPage() {
  return (
    <MainLayout>
      <LegalPageStyled>
        <Title title={"Legal Notices"} />
        <InnerLayout>
          <span>Effective as of 16/01/2022</span>

          <p>
            In accordance with the provisions of Articles 6-III and 19 of Law No. 2004-575 of June 21, 2004 for Confidence in the Digital Economy, known as the L.C.E.N., users and visitors,
            hereinafter the "User", of the site www.the-javascript-wizard.com, hereafter the "Site", are informed of the present legal notice.
          </p>
          <p>The connection and navigation on the Site by the User implies full and unreserved acceptance of these legal notices.</p>
          <p>The latter are accessible on the Site under the heading "Legal Notice".</p>

          <h2>ARTICLE 1 - THE PUBLISHER</h2>

          <p>
            The editing and the direction of the publication of the Site is ensured by LOUIS Chloé Eden, domiciled at 80 Av. Ledru Rollin, 75012 Paris, whose e-mail address is eden.ls.dev@gmail.com.
          </p>
          <p>hereafter the "Publisher".</p>

          <h2>ARTICLE 2 - THE HOST</h2>

          <p>The host of the Site is the company The Javascript Wizard, whose head office is located at 80 Av. Ledru Rollin, 75012 Paris, with the contact email address eden.ls.dev@gmail.com</p>

          <h2>ARTICLE 3 - ACCESS TO THE SITE</h2>

          <p>
            The Site is accessible anywhere, 7 days a week, 24 hours a day, except in case of force majeure, scheduled or unscheduled interruption and which may result from a maintenance necessity. In
            the event of modification, interruption or suspension of the Site, the Editor cannot be held responsible.
          </p>

          <h2>ARTICLE 4 - DATA COLLECTION</h2>

          <p>The Site is exempted from declaration to the National Commission for Data Processing and Liberties (CNIL) insofar as it does not collect personal information.</p>

          <p>
            Any use, reproduction, distribution, marketing, modification of all or part of the Site, without the authorization of the Editor is prohibited and may result in legal action and
            prosecution as provided for in particular by the Code of Intellectual Property and the Civil Code.
          </p>

          <h2>ARTICLE 5 - PRIVACY POLICY</h2>

          <p>
            In France, personal data are protected by the law n° 78-87 of January 6, 1978, the law n° 2004-801 of August 6, 2004, the article L. 226-13 of the Penal Code and the European Directive of
            October 24, 1995.
          </p>

          <p>
            When using the site, the following information may be collected: the URL of the links through which the user accessed the site, the user's access provider, the user's Internet protocol
            (IP) address.
          </p>

          <p>
            In any case, The Javascript Wizard only collects personal information relative to the user if the latter consents to it, by proceeding to their seizure, within the framework of a contact
            form, without any obligation to provide this information.
          </p>

          <h3>ARTICLE 5.1 - CONTACT FORM</h3>

          <p>
            The information collected on the contact form of the Site is sent by e-mail to make commercial proposals related to the services of The Javascript Wizard.This information is sent by the
            https protocol under an SSL certificate allowing to protect the information during its sending. They are kept for 1 year and are intended for LOUIS Chloé Eden. These data are the
            following: <br />
            - Name <br />
            - E-mail address
            <br />- Free message in which other personal data could be filled in by the user
          </p>

          <p>
            The data provided is only stored on the e-mail box of LOUIS Chloé Eden. The devices where these data are accessible (cell phones and computers) are protected by a password. In any case,
            the user can refuse to provide his personal data. In this case, he will not be able to send a form.
          </p>

          <p>
            In accordance with the law "informatique et libertés", any user has a right of access, rectification and opposition to personal data concerning him by contacting : LOUIS Chloé Eden, via
            eden.ls.dev@gmail.com. No personal information of the user of the Site is published without the user's knowledge, exchanged, transferred, ceded or sold on any medium to third parties.
          </p>

          <h3>ARTICLE 5.2 - HYPERTEXT LINKS AND COOKIES</h3>

          <p>
            The Site contains a number of hypertext links to other sites, set up with the authorization of LOUIS Chloé Eden. However, the owner does not have the possibility of checking the contents
            of the sites thus visited, and consequently will not assume any responsibility of this fact.
          </p>

          <p>
            The navigation on the Site is likely to cause the installation of cookie(s) on the user's computer. A cookie is a small file, which does not allow the identification of the user, but which
            records information relating to the navigation of a computer on a site. We automatically collect only those cookies that are essential to the proper functioning of the website during a
            simple navigation. In no case the Site uses third party companies to collect personal information.{" "}
          </p>

          <h2>ARTICLE 6 - APPLICABLE LAW AND JURISDICTION</h2>

          <p>Any dispute in connection with the use of the site www.the-javascript-wizard.com is subject to French law. Exclusive jurisdiction is given to the competent courts of Paris. </p>
        </InnerLayout>
      </LegalPageStyled>
    </MainLayout>
  );
}

const LegalPageStyled = styled.div`
  span {
    font-size: 0.8rem;
    margin: 1rem 0;
  }

  p {
    font-size: 1rem;
    margin: 1rem 0;
  }

  h2 {
    margin: 2rem 0;
  }

  h3 {
    font-size: 1.2rem;
  }
`;

export default LegalPage;
