import React from "react";
import styled from "styled-components";
import { MainLayout } from "../styles/Layouts";
import Title from "../Components/Title";
import ImageSection from "../Components/ImageSection";
import ServicesSection from "../Components/ServicesSection";
// import ReviewSection from "../Components/ReviewSection";
import PrimaryButton from "../Components/PrimaryButton";
import { Link } from "react-router-dom";

function AboutPage() {
  return (
    <MainLayout>
      <AboutStyled>
        <Title title={"About Me"} />
        <ImageSection />
        <ServicesSection />
        {/* <ReviewSection /> */}
        <div className="cta">
          <Link to="/portfolio">
            <PrimaryButton title={"See my projects"} />
          </Link>
        </div>
      </AboutStyled>
    </MainLayout>
  );
}

const AboutStyled = styled.section`
  .cta {
    text-align: center;
  }
`;

export default AboutPage;
