import cookingRecipes from "../img/portfolioImg/cooking-recipe-aggregator.jpg";
import workoutsMap from "../img/portfolioImg/map-workouts.jpg";
import seriesDatabase from "../img/portfolioImg/series-database.jpg";
import mernBlog from "../img/portfolioImg/mern-blog.jpg";
import artGallery from "../img/portfolioImg/art-gallery.jpg";
import arcaneCarousel from "../img/portfolioImg/arcane-carousel.jpg";
import kanbanApp from "../img/portfolioImg/kanban-app.jpg";
import bookRatingApp from "../img/portfolioImg/book-rating-app.jpg";
import mobileMoviesApp from "../img/portfolioImg/react-native-movie-db.png";
import pixelTrainerPortfolio from "../img/portfolioImg/khloros-portfolio-thumbnail.png";
import findEatApp from "../img/portfolioImg/findEat-app.png";
import majelanWebapp from "../img/portfolioImg/majelan-webapp.png";

const portfolios = [
  {
    id: 1,
    category: "ReactJS",
    category2: "Javascript",
    category3: "Typescript",
    image: majelanWebapp,
    link2: "https://play.majelan.com/",
    title: "Majelan Podcast Platform",
    text: "With Next, React Query, Firebase, Tailwind...",
  },
  {
    id: 2,
    category: "ReactJS",
    category2: "Javascript",
    category3: "CSS",
    image: pixelTrainerPortfolio,
    link2: "https://the-pixel-trainer.netlify.app/",
    title: "Pixel Trainer Portfolio",
    text: "With CSS animations, Framer Motion, Swiper JS & scrolling animations",
  },
  {
    id: 3,
    category: "React Native",
    image: findEatApp,
    link2: "https://expo.dev/@edls/findEat?serviceType=classic&distribution=expo-go",
    title: "FindEat App",
    text: "With Firebase for auth, using React Native expo (with maps & json data)",
  },
  {
    id: 4,
    category: "ReactJS",
    category2: "Javascript",
    category3: "CSS",
    image: artGallery,
    link1: "https://github.com/Ed-Ls/photo-gallery",
    link2: "https://travel-beyond-creativity.netlify.app/",
    title: "Art Gallery",
    text: "With horizontal scroll, parallax effect using Intersection Observer & Locomotive Scroll",
  },
  {
    id: 5,
    category: "Node.js",
    category2: "SQL",
    category3: "ReactJS",
    image: kanbanApp,
    link1: "https://github.com/Ed-Ls/kanban-app",
    link2: "https://www.youtube.com/watch?v=7weil4R94YU",
    title: "Kanban Fullstack App",
    text: "Kanban app, made with ReactJS & Tailwind for the frontend, Node.js, SQL, Postgres for the backend.",
  },
  {
    id: 6,
    category: "React Native",
    image: mobileMoviesApp,
    link1: "https://github.com/Ed-Ls/react-native-movie-app",
    link2: "https://youtu.be/Kuq7CfI9ofc",
    title: "Movie DB Mobile App",
    text: "Made with React Native CLI. Users can find movies by category, see movie details and launch trailers.",
  },
  {
    id: 7,
    category: "Typescript",
    category2: "ReactJS",
    category3: "CSS",
    image: bookRatingApp,
    link1: "https://github.com/Ed-Ls/greatest-books-ts",
    title: "Book Rating App",
    text: "Neumorphism designed app to add books, comment & rate them. Made with ReactJS, Typescript & Firebase",
  },
  {
    id: 8,
    category: "Node.js",
    category2: "MongoDB",
    category3: "ReactJS",
    image: mernBlog,
    link1: "https://github.com/Ed-Ls/mern-stack-blog",
    title: "MERN Stack Blog",
    text: "API designed with Node.js / Express, & app made with React.js (using axios for API Calls)",
  },
  {
    id: 9,
    category: "ReactJS",
    category2: "Javascript",
    category3: "CSS",
    image: arcaneCarousel,
    link1: "https://github.com/Ed-Ls/arcane-carousel",
    title: "Arcane Animated Slider ",
    text: "Video game like selection player page, with Slick Slider and Framer Motion (micro-animations)",
  },
  {
    id: 10,
    category: "Node.js",
    category2: "Javascript",
    image: seriesDatabase,
    link1: "https://github.com/Ed-Ls/best-series-collection",
    title: "Top Series Database",
    text: "IMDB-like website, every HTML is rendered with EJS & the server is created with Node.js / Express",
  },
  {
    id: 11,
    category: "Javascript",
    category2: "CSS",
    image: cookingRecipes,
    link1: "https://github.com/Ed-Ls/cooking-recipe-aggregator",
    link2: "https://forkify-ed.netlify.app/",
    title: "Recipe Aggregator",
    text: "Created with SCSS and VanillaJS, with API calls and a search bar to get thousands of recipes",
  },
  {
    id: 12,
    category: "Javascript",
    category2: "CSS",
    image: workoutsMap,
    link1: "https://github.com/Ed-Ls/workouts-interactive-map",
    link2: "https://mapty-ed.netlify.app/",
    title: "Map your workouts",
    text: "Map generated with Leaflet library, display detailed landmarks with your workouts",
  },
];

export default portfolios;
