import React from "react";
import styled from "styled-components";

function Title({ title, span }) {
  return (
    <TitleStyled>
      <h2>{title}</h2>
    </TitleStyled>
  );
}

const TitleStyled = styled.div`
  position: relative;

  h2 {
    color: var(--white-color);
    font-size: 2.5rem;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 1rem;

    &::before {
      content: "";
      position: absolute;
      bottom: -3px;
      width: 7rem;
      height: 0.4rem;
      background-color: var(--background-light-color-2);
      border-radius: 15px;
      left: 0.2rem;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: -3px;
      width: 2rem;
      height: 0.4rem;
      background-color: var(--primary-color);
      border-radius: 15px;
      left: 0.2rem;
    }

    @media screen and (max-width: 650px) {
      font-size: 1.9rem;
    }
  }
`;

export default Title;
