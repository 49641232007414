import React from "react";
import styled from "styled-components";
import { InnerLayout } from "../styles/Layouts";
import Techno from "./Techno";
import Title from "./Title";

import CSSlog from "../img/technos/CSS.png";
import HTMLlog from "../img/technos/HTML.png";
import PostGreSQLlog from "../img/technos/postgresql.png";
import Javascriptlog from "../img/technos/JS.png";
import MongoDBlog from "../img/technos/MongoDB.png";
import NodeJSlog from "../img/technos/Nodejs.png";
import Reactlog from "../img/technos/React.png";
import SQLlog from "../img/technos/SQL.png";
import Wordpresslog from "../img/technos/Wordpress.png";
import Photoshoplog from "../img/technos/Photoshop.png";
import Figmalog from "../img/technos/Figma.png";
import Typescriptlog from "../img/technos/typescript.png";
import Nextjslog from "../img/technos/nextjs.png";
import Firebaselog from "../img/technos/Firebase.png";
import Netlog from "../img/technos/dotnetCore.png";

function Skills() {
  return (
    <SkillsStyled>
      <Title title={"My Skills"} />
      <InnerLayout>
        <h6>Frontend</h6>
        <div className='skills-front'>
          <Techno logo={HTMLlog} name={"HTML"} />
          <Techno logo={CSSlog} name={"CSS"} />
          <Techno logo={Javascriptlog} name={"Javascript"} />
          <Techno logo={Typescriptlog} name={"Typescript"} />
          <Techno logo={Reactlog} name={"ReactJS"} />
          <Techno logo={Nextjslog} name={"NextJS"} />
          <Techno logo={Reactlog} name={"React Native"} />
          <Techno logo={Wordpresslog} name={"Wordpress"} />
        </div>
        <h6>Backend</h6>
        <div className='skills-back'>
          <Techno logo={NodeJSlog} name={"Node.js"} />
          <Techno logo={Netlog} name={".Net"} />
          <Techno logo={MongoDBlog} name={"MongoDB"} />
          <Techno logo={SQLlog} name={"SQL"} />
          <Techno logo={PostGreSQLlog} name={"PostgreSQL"} />
          <Techno logo={Firebaselog} name={"Firebase"} />
        </div>
        <h6>Design</h6>
        <div className='skills-design'>
          <Techno logo={Photoshoplog} name={"Photoshop"} />
          <Techno logo={Figmalog} name={"Figma"} />
        </div>
      </InnerLayout>
    </SkillsStyled>
  );
}

const SkillsStyled = styled.section`
  h6 {
    margin-bottom: 1rem 0;
  }
  .skills-front {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 1.2rem;
    margin: 1.3rem 3rem;

    @media screen and (max-width: 800px) {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 2.4rem;
      margin: 1.1rem 1.5rem;
    }

    @media screen and (max-width: 500px) {
      font-size: 0.8rem;
      grid-column-gap: 2.4rem;
      margin: 0.8rem 0rem;
    }
  }

  .skills-back {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 1.2rem;
    margin: 1.3rem 3rem;

    @media screen and (max-width: 800px) {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 2rem;
      margin: 1.1rem 1.5rem;
    }

    @media screen and (max-width: 500px) {
      font-size: 0.8rem;
      grid-column-gap: 2.4rem;
      margin: 0.8rem 0rem;
    }
  }

  .skills-design {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 1.3rem 3rem;

    @media screen and (max-width: 800px) {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 2.4rem;
      margin: 1.1rem 1.5rem;
    }

    @media screen and (max-width: 500px) {
      font-size: 0.8rem;
      grid-column-gap: 2.4rem;
      margin: 0.8rem 0rem;
    }
  }
`;

export default Skills;
