import React, { useState } from "react";
import Title from "../Components/Title";
import { MainLayout, InnerLayout } from "../styles/Layouts";
import projects from "../data/projects";
import Menu from "../Components/Menu";
import Button from "../Components/Button";
import styled from "styled-components";

//filtered buttons
//Use ...new Set() to avoid siblings categories
let allButtons = ["All", ...new Set(projects.map((item) => item.category))];
allButtons.push(...new Set(projects.map((item) => (item.category2 ? item.category2 : item.category))));
let filteredButtons = [...new Set(allButtons)];

function PortfolioPage() {
  const [menuItem, setMenuItem] = useState(projects);
  const [button, setButton] = useState(filteredButtons);

  //filter function for categories
  const filter = (button) => {
    if (button === "All") {
      setMenuItem(projects);
      return;
    }

    //when adding more than 1 category to projects
    const filtered1 = projects.filter((item) => item.category === button);
    const filtered2 = projects.filter((item) => item.category2 === button);
    const filtered3 = projects.filter((item) => item.category3 === button);
    const filtered4 = projects.filter((item) => item.category4 === button);
    const filteredData = filtered1.concat(filtered2).concat(filtered3).concat(filtered4);
    setMenuItem(filteredData);
  };
  return (
    <PortfolioPageStyled>
      <MainLayout>
        <Title title={"Portfolio"} />
        <InnerLayout>
          <Button filter={filter} button={button} />
          <Menu menuItem={menuItem} />
          <div className='github-link'>
            Visit my{" "}
            <a href='https://github.com/Ed-Ls' target='_blank' rel='noreferrer'>
              Github
            </a>{" "}
            to see more projects!{" "}
          </div>
        </InnerLayout>
      </MainLayout>
    </PortfolioPageStyled>
  );
}

const PortfolioPageStyled = styled.div`
  .github-link {
    text-align: center;
    margin-top: 3rem;
    font-weight: bold;

    a {
      color: var(--primary-color);
      font-size: 1.3rem;
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 500px) {
    button {
      font-size: 1rem;
      padding: 0.6rem 0.8rem;
    }
  }
`;

export default PortfolioPage;
