import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import avatar from "../img/avatar-empty.jpg";
import avatar2 from "../img/eden-ls-2.png";
import GithubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

function Navigation({ handleCallback }) {
  console.log(handleCallback);

  return (
    <NavigationStyled>
      <div className="avatar">
        <img src={avatar} alt="" />
      </div>
      <img className="avatar-3d" src={avatar2} alt="" />

      <ul className="nav-items">
        <li className="nav-item">
          <NavLink
            activeClassName="active-class"
            to="/"
            exact
            onClick={handleCallback}
          >
            Home
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            activeClassName="active-class"
            to="/about"
            exact
            onClick={handleCallback}
          >
            About
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            activeClassName="active-class"
            to="/resume"
            exact
            onClick={handleCallback}
          >
            Resume
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            activeClassName="active-class"
            to="/portfolio"
            exact
            onClick={handleCallback}
          >
            Portfolio
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            activeClassName="active-class"
            to="/contact"
            exact
            onClick={handleCallback}
          >
            Contact
          </NavLink>
        </li>
      </ul>
      <footer className="footer">
        <p>@2023 Eden C. Louis Portfolio</p>
        <div className="icons">
          <a
            href="https://github.com/Ed-Ls"
            target="_blank"
            rel="noreferrer"
            className="icon i-github"
          >
            <GithubIcon />
          </a>
          <a
            href="https://www.linkedin.com/in/e-c-ls/"
            target="_blank"
            rel="noreferrer"
            className="icon i-linkedin"
          >
            <LinkedInIcon />
          </a>
        </div>
        <NavLink className="legals" to="/legals" exact>
          See the legal notices
        </NavLink>
      </footer>
    </NavigationStyled>
  );
}

const NavigationStyled = styled.nav`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  border-right: 1px solid var(--border-color);

  .avatar {
    width: 100%;
    border-bottom: 1px solid var(--border-color);
    text-align: center;
    padding: 1.5rem 0;

    img {
      width: 70%;
      object-fit: fill;
      border-radius: 50%;
      border: 6px solid var(--border-color);
    }
  }

  .avatar-3d {
    width: 68%;
    position: absolute;
    top: 29px;
    left: 45px;

    img {
      width: 70%;
      object-fit: cover;
    }
  }

  .nav-items {
    width: 100%;
    text-align: center;

    .active-class {
      background-color: var(--primary-color);
      color: white;
    }

    li {
      display: block;

      a {
        display: block;
        padding: 0.4rem 0;
        position: relative;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 1px;
        color: var(--font-light-color);

        &:hover {
          cursor: pointer;
          /* color: var(--primary-color); */
        }
        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0;
          height: 50%;
          background-color: var(--primary-color);
          transition: All 0.4s ease-in-out;
          opacity: 0.2;
          transform-origin: right;
        }
      }
    }

    a:hover::before {
      width: 100%;
      height: 100%;
    }
  }

  footer {
    border-top: 1px solid var(--border-color);
    width: 100%;

    .legals {
      display: block;
      font-size: 0.5rem;
      text-align: center;
      margin: 0.5rem auto;
    }

    p {
      padding-top: 1rem;
      font-size: 0.7rem;
      display: block;
      text-align: center;
    }

    .icons {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 0.7rem;

      svg {
        font-size: 1.2rem;
        color: var(--font-light-color);
        margin: 0 0.3rem;

        &:hover {
          color: var(--primary-color);
        }
      }
    }
  }
`;

export default Navigation;
