import React from "react";
import styled from "styled-components";

function ResumeItem({ year, title, subTitle, text }) {
  return (
    <ResumeItemStyled>
      <div className='left-content'>
        <p>{year}</p>
      </div>

      <div className='right-content'>
        <h5>{title}</h5>
        <h6>{subTitle}</h6>
        <p>{text}</p>
      </div>
    </ResumeItemStyled>
  );
}

const ResumeItemStyled = styled.div`
  display: flex;
  margin: 2rem 0;

  @media screen and (max-width: 650px) {
    p {
      font-size: 1rem;
    }
  }

  .left-content {
    width: 450px;
    margin-top: 0.2rem;
    padding-left: 30px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: -9px;
      top: 5px;
      height: 12px;
      width: 12px;
      border-radius: 100px;
      border: 2px solid var(--border-color);
      background-color: var(--background-dark-color);
    }

    p {
      display: inline-block;

      @media screen and (max-width: 650px) {
        font-size: 0.8rem;
      }
    }
  }

  .right-content {
    padding-left: 5rem;
    position: relative;
    width: 130%;

    @media screen and (max-width: 500px) {
      padding-left: 1.5rem;
      width: 220%;
    }

    &::before {
      content: "";
      position: absolute;
      left: -3px;
      top: 15px;
      width: 3rem;
      height: 0.3rem;
      background-color: var(--border-color);
      border-radius: 15px;

      @media screen and (max-width: 850px) {
        width: 2rem;
        left: 20px;
      }

      @media screen and (max-width: 500px) {
        display: none;
      }
    }

    h5 {
      color: var(--primary-color);
      font-size: 1.5rem;
      padding-bottom: 0.3rem;

      @media screen and (max-width: 650px) {
        font-size: 1.1rem;
      }
    }

    h6 {
      padding-bottom: 0.3rem;
      font-size: 1.3rem;

      @media screen and (max-width: 650px) {
        font-size: 1rem;
      }
    }

    p {
      @media screen and (max-width: 650px) {
        font-size: 0.8rem;
      }
    }
  }
`;

export default ResumeItem;
