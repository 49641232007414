import React from "react";
import styled from "styled-components";

function Button({ filter, button }) {
  return (
    <ButtonsStyled>
      {button.map((btn, i) => {
        let btnJoined = btn.toLowerCase().replace(/\W/g, "");

        return (
          <ButtonStyled
            className={btnJoined}
            key={i}
            onClick={() => filter(btn)}
          >
            {btn}
          </ButtonStyled>
        );
      })}
    </ButtonsStyled>
  );
}

const ButtonStyled = styled.button`
  outline: none;
  border: none;
  background-color: var(--primary-color);
  padding: 0.6rem 1rem;
  font-size: inherit;
  color: #fff;
  margin-bottom: 0.6rem;
  margin-right: 0.6rem;
  border-radius: 15px;
  cursor: pointer;

  &:hover {
    filter: brightness(0.8);
  }

  &:active,
  &:focus {
    font-size: 1.4rem;

    @media screen and (max-width: 400px) {
      font-size: 1rem;
    }

    filter: none;
    box-shadow: 0px 0px 5px 5px var(--font-light-color-2);
  }
`;

const ButtonsStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 80%;
  margin: 2rem auto;

  .css {
    background-color: #8d0bad;
  }

  .nodejs {
    background-color: #a06cd5;
  }

  .javascript {
    background-color: #d2b7e5;
  }

  .reactjs {
    background-color: #6b33ac;
  }

  .reactnative {
    background-color: #3f37c9;
  }

  .typescript {
    background-color: #c270cf;
  }

  .sql {
    background-color: #3a0ca3;
  }

  .mongodb {
    background-color: #4361ee;
  }
`;

export default Button;
