import React from "react";
import styled from "styled-components";

function ContactItem({ icon, title, contact }) {
  return (
    <ContactItemStyled>
      <div className="contact-line">
        <div className="left-content">
          <p>{icon}</p>
        </div>
        <div className="right-content">
          <h6>{title}</h6>
          <p>{contact}</p>
        </div>
      </div>
    </ContactItemStyled>
  );
}

const ContactItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 2rem;

  @media screen and (max-width: 1000px) {
    width: 80%;
    justify-content: center;
    margin: auto;
    margin-bottom: 1.5rem;
    padding: 0rem 0.5rem;
  }

  @media screen and (max-width: 650px) {
    width: 100%;
    justify-content: center;
    margin: auto;
    margin-bottom: 1.5rem;
  }

  .contact-line {
    display: flex;
    align-items: start;

    @media screen and (max-width: 600px) {
      margin-left: -2rem;
      svg {
        display: none;
      }
    }
  }

  .left-content {
    padding: 1rem;
    display: flex;
    align-items: start;
    justify-content: space-between;

    @media screen and (max-width: 1000px) {
      margin: 1rem;
      padding: 0.7rem;
    }

    @media screen and (max-width: 650px) {
      margin: 0.6rem;
      padding: 0;
    }

    svg {
      font-size: 2.2rem;
      padding-top: 0.3rem;
    }
  }

  .right-content {
    margin: 1rem;

    @media screen and (max-width: 650px) {
      width: 100%;
      justify-content: center;
      margin: 0.4rem 0rem;
      padding: 0 0.6rem;

      p {
        font-size: 0.8rem;
      }
    }
  }
`;

export default ContactItem;
