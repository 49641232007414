import { useState, useEffect } from "react";
import styled from "styled-components";
import Sidebar from "./Components/Sidebar.js";

import HomePage from "./Pages/HomePage.js";
import AboutPage from "./Pages/AboutPage.js";
import ResumePage from "./Pages/ResumePage.js";
import PortfolioPage from "./Pages/PortfolioPage.js";
import ContactPage from "./Pages/ContactPage.js";
import LegalPage from "./Pages/LegalPage.js";
import BrightnessIcon from "@material-ui/icons/Brightness2";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import { IconButton } from "@material-ui/core";
import ScrollToTop from "./Components/ScrollToTop";

import { Route, Switch as Switching } from "react-router";

function App() {
  //toggle dark/light mode
  const [theme, setTheme] = useState("light-theme");
  const [checked, setChecked] = useState("");
  const [navToggle, setNavToggle] = useState(false);

  useEffect(() => {
    document.documentElement.className = theme;
  }, [theme]);

  const themeToggler = () => {
    if (theme === "light-theme") {
      setTheme("dark-theme");
      setChecked(true);
    } else {
      setTheme("light-theme");
      setChecked(false);
    }
  };

  //customize material ui switch colors
  const CustomSwitch = withStyles({
    colorSecondary: {
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#FFF",
      },
    },
  })(Switch);

  const handleCallback = (childData) => {
    setNavToggle(false);
  };

  return (
    <div className="App">
      <Sidebar
        navToggle={navToggle}
        setNavToggle={setNavToggle}
        handleCallback={handleCallback}
      />

      <div className="light-dark-mode">
        <div className="left-content">
          <BrightnessIcon />
          <CustomSwitch
            value=""
            checked={checked}
            onClick={themeToggler}
            inputProps={{ "aria-label": "" }}
            size="medium"
          />
        </div>
        <div className="right-content"></div>
      </div>

      <div className="hamburger-menu">
        <IconButton onClick={() => setNavToggle(!navToggle)}>
          <MenuIcon />
        </IconButton>
      </div>

      <MainContentStyled>
        <div className="lines">
          <div className="line-1"></div>
          <div className="line-2"></div>
          <div className="line-3"></div>
          <div className="line-4"></div>
        </div>

        <Switching>
          <ScrollToTop>
            <Route path="/" exact>
              <HomePage />
            </Route>
            <Route path="/about" exact>
              <AboutPage />
            </Route>
            <Route path="/resume" exact>
              <ResumePage />
            </Route>
            <Route path="/portfolio" exact>
              <PortfolioPage />
            </Route>
            <Route path="/contact" exact>
              <ContactPage />
            </Route>
            <Route path="/legals" exact>
              <LegalPage />
            </Route>
          </ScrollToTop>
        </Switching>
      </MainContentStyled>
    </div>
  );
}

const MainContentStyled = styled.main`
  position: relative;
  margin-left: 15rem;
  min-height: 100vh;

  @media screen and (max-width: 1200px) {
    margin-left: 0;
  }

  .lines {
    position: absolute;
    min-height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    z-index: -1;
    opacity: 0.6;

    .line-1,
    .line-2,
    .line-3,
    .line-4 {
      width: 1px;
      min-height: 100vh;
      background-color: var(--border-color);
    }
  }
`;

export default App;
